import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { Heading, HorizontalDivider, Footer } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
import TeamContactUs from "../sections/team/TeamContactUs";
// import HomeHero from "../sections/home/HomeHero";

// TODO: #32 team - from the founders
// TODO: #33 team - member cards
// TODO: #34 team - member popup
// TODO: #35 team - company news
// TODO: #36 team - learn more
// TODO: #37 team - contact
// TODO: #45 team - top-margin for header component [no whitespace above pic]

const Team = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='Team'
    >

      
      {/* <Hero 
        variant='SevenFiveRatio'
        containerBackgroundColor='rgb(244, 244, 244)'
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={data.homeHeader.data.header}
        subheadingCopy={data.homeHeader.data.subheader}
        buttonLabel={data.homeHeader.data.action}
        buttonUrl={data.homeHeader.data.action_url}
        heroImageUrl={data.homeHeader.data.attached_images[0].url}
        heroImageAlt={data.homeHeader.data.attached_images[0].filename}
      /> */}
      
      <Heading h2> from the founders </Heading>
      <HorizontalDivider />
      <Heading h2> Our team </Heading>
      <HorizontalDivider />
      <Heading h2> company news </Heading>
      <HorizontalDivider />
      <Heading h2> learn more </Heading>
      <HorizontalDivider />
      {/* <Heading h2> Contact us </Heading> */}
      <TeamContactUs />
      <Footer />

    </Layout>
  )
}

// export const query = graphql`
//   query TeamPageContentQuery {
    
//   }
// `

export default Team;